import { useState } from 'react'
import { Link } from 'react-router-dom'
import MenuList from '../MenuList'
import styles from './ListItem.module.sass'

export const ListItem = ({ label, link, submenu, setMenuState }) => {
  const [isOpen, setOpen] = useState(false)

  const test = () => {
    setOpen(false)
    setMenuState()
  }

  return (
    <li className={styles.listItem}>
      <Link to={link} className={styles.link} onClick={() => submenu && setOpen(true)} data-label={label}>{label}</Link>
      {submenu && <MenuList links={submenu} isSubmenu isOpen={isOpen} setMenuState={setMenuState} test={test} />}
    </li>
  )
}