import cx from 'classnames'
import styles from './Layout.module.sass'

export const Section = ({ backgroundColor, className, children, ...rest }) => {
  const classNames = cx(
    styles.section,
    styles[backgroundColor],
    className
  )

  return <section className={classNames} {...rest}>{children}</section>
}

Section.defaultProps = {
  backgroundColor: 'black'
}

export const Container = ({ className, children }) => {
  const classNames = cx(
    styles.container,
    className
  )

  return <div className={classNames}>{children}</div>
}