import cx from 'classnames'
import Icon from '../Icon'
import Text from '../Text'
import { links } from './data'
import styles from './SocialMedia.module.sass'

export const SocialMedia = ({ type, withIcon }) => {
  const classNames = cx(
    styles.link,
    { [styles.withIcon]: withIcon }
  )

  return (
    <a className={classNames} href={`${links[type]}`}>
      {withIcon && <Icon name={type} />}
      <Text as="span" color="white" className={styles.label}>{type}</Text>
    </a>
  )
}