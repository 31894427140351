import { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import { createBrowserHistory } from 'history'
import { Apollo } from './Apollo'
import Header from 'components/Header'
import Footer from './components/Footer'
import './styles.sass'
import { Section, Container } from './components/Layout'

const Home = lazy(() => import('./views/Home'))
const Page = lazy(() => import('./views/Page'))
const GalleryTiles = lazy(() => import('./components/GalleryTiles'))
const Gallery = lazy(() => import('./components/Gallery'))
const Contact = lazy(() => import('./views/Contact'))
const Content = lazy(() => import('components/Content'))

const App = () => {
  const { pathname } = useLocation
  const history = createBrowserHistory()

  useEffect(() => {
    window.scrollTo(0,0)
  }, [pathname])

  return (
    <Apollo>
      <div className="App">
        <Router history={history}>
          <Header />
          <main>
            <Suspense fallback={null}>
              <Switch>
                <Route exact path="/galeria/:category"><Page><Gallery /></Page></Route>
                <Route path="/galeria"><Page><Section backgroundColor="white"><Container><GalleryTiles /></Container></Section></Page></Route>
                <Route path="/kontakt"><Page><Contact /></Page></Route>
                <Route path="/o-mnie"><Page><Content /></Page></Route>
                <Route path="/polityka-prywatnosci"><Page><Content /></Page></Route>
                <Route path="/oferta/:subpage"><Page><Content /></Page></Route>
                <Route exact path="/"><Home /></Route>  
              </Switch>
            </Suspense>
          </main>
          <Footer />
          <MessengerCustomerChat pageId="578982875465162" appId="260506348854573" />
        </Router>
      </div>
    </Apollo>
  )
}

export default App