import classnames from 'classnames'
import ListItem from '../ListItem'
import styles from './MenuList.module.sass'

export const MenuList = ({ links, isSubmenu, isOpen, setMenuState, test }) => {
  const classNames = classnames(
    isSubmenu ? styles.submenu : styles.menu,
    { [styles.isOpen]: isOpen }
  )
  const lala = (event) => {
    if (isSubmenu) {
      test()
    }
    else if (event.target.dataset.label !== 'Oferta') {
      return setMenuState()
    }
    else return null
  }

  return (
    <ul className={classNames} onClick={lala}>
      {links.map(item => 
        <ListItem key={item.label} setMenuState={setMenuState} label={item.label} link={isSubmenu ? `/oferta${item.path}` : item.link} submenu={item.submenu} />
      )}
    </ul>
  )
}