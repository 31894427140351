import classnames from 'classnames'
import { useQuery } from '@apollo/client'
import MenuList from '../MenuList'
import { GET_OFFER_MENU } from 'queries/getOfferMenu'
import { links } from './links'
import styles from './Nav.module.sass'

export const Nav = ({ isOpen, setMenuState }) => {
  const { loading, error, data } = useQuery(GET_OFFER_MENU)

  const classList = classnames(
    styles.nav,
    { [styles.isOpen]: isOpen }
  )
  
  if (loading) return null
  if (error) return <div>error</div>
  console.log(data, error)
  const shortenData = data.menuItems.edges.map(item => item.node)
  links[3].submenu = shortenData
  
  return (
    <nav className={classList}>
      <MenuList links={links} setMenuState={setMenuState} />
    </nav>
  )
}