import React, { useState } from 'react';
import classNames from 'classnames';
import './icon.sass';

const Icon = ({ name, size = 2, className }) => {
  const [icon, setIcon] = useState(null);
  const classList = classNames (
    'icon',
    size && `icon--${size}`,
    className && className
  );

  import(`../../assets/icons/${name}.svg`).then( image => { setIcon(image)});

  if (icon) return <img src={icon.default} className={classList} alt={name} />
  return null;
}

export default Icon;