import cx from 'classnames'
import styles from './Text.module.sass'

export const Text = ({ color, small, as, className, children, ...rest }) => {
  const Element = as ? as : 'p'
  const classNames = cx(
    small ? styles.smallText : styles.text,
    styles[color],
    className
  )
  
  return <Element className={classNames} {...rest}>{children}</Element>
}

Text.defaultProps = {
  color: 'black'
}