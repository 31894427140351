import { Link } from 'react-router-dom'
import Text from '../Text'
import SocialMedia from '../SocialMedia'
import { Container } from '../Layout'
import styles from './Footer.module.sass'

export const Footer = () => (
  <footer className={styles.footer}>
    <Container className={styles.container}>
      <Text as="span" color="white">Copyright 2021</Text>
      <nav className={styles.nav}>
        <SocialMedia type="facebook" />
        <SocialMedia type="instagram" />
        <SocialMedia type="youtube" />
        <Link to="/polityka-prywatnosci" className={styles.link}>Polityka Prywatności</Link>
      </nav>
      <Text as="span" color="white">B.Spyrka</Text>
    </Container>
  </footer>
)