import { useState, useEffect } from 'react'
import Nav from './components/Nav'
import { ReactComponent as Burger } from '../../assets/icons/burger.svg'
import logo from '../../assets/logo_misia_short.png'
import styles from './Header.module.sass'

export const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const [onPageTop, setOnPageTop] = useState(true)

  const handleScroll = () => {
    const pageOffset = window.pageYOffset

    if (pageOffset > 100 && onPageTop) setOnPageTop(false)
    else if (pageOffset < 100 && !onPageTop) setOnPageTop(true)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return (() => window.removeEventListener('scroll', handleScroll))
  })
  const test = () => setOpen(!isOpen)

  return (
    <header id="header" className={`${styles.header} ${!onPageTop && styles.onPageTop}`}>
      <div className={styles.container}>
        <img src={logo} alt="logo Art of Misia" className={styles.logo} />
        <Burger className={styles.burger} onClick={() => setOpen(!isOpen)} />
        <Nav isOpen={isOpen} setMenuState={test} />
      </div>
    </header>
  )
}