import { gql } from '@apollo/client'

export const GET_OFFER_MENU = gql`
  query GetOfferMenu {
    menuItems {
      edges {
        node {
          label
          path
        }
      }
    }
  }
`