export const links = [
  {
    "label": "Strona główna",
    "link": "/",
    "submenu": false
  },
  {
    "label": "Galeria",
    "link": "/galeria",
    "submenu": false
  },
  {
    "label": "O mnie",
    "link": "/o-mnie",
    "submenu": false
  },
  {
    "label": "Oferta",
    "link": false,
    "submenu": false
  },
  {
    "label": "Kontakt",
    "link": "/kontakt",
    "submenu": false
  },
]